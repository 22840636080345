import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import ProjectContent from "../components/Projects/ProjectContent"
import ProjectHero from "../components/Projects/ProjectHero"
import Back from "../components/Layout/Back"
import Navigation from "../components/Layout/Navigation"
import Contact from "../components/Home/Contact"

const ProjectsPage = () => (
	<Layout>
		<SEO
			title="Referenzen"
			description="Überzeugen Sie sich selbst. Sehen Sie sich hier eine Reihe ausgewählter Projekte und Umsetzungen etwas genauer an."
		/>
		<Back color={"#e9c46a"} />
		<Navigation contact={false} active="projects" />
		<ProjectHero />
		<ProjectContent />
		<Contact />
	</Layout>
)

export default ProjectsPage
