import React from "react"
import { FiArrowRight, FiSettings } from "react-icons/fi"

const ProjectCard = ({ project }) => {
  return (
    <div>
      <div className="flex">
        <div data-aos="zoom-in-up" className="my-10">
          <div className="flex justify-between">
            <div className="lato text-base lg:text-xl text-left text-gray-700">
              {project.frontmatter.client}
            </div>
            <div className="lato text-base lg:text-xl text-gray-700">{project.frontmatter.date}</div>
          </div>
          <div className="lato text-2xl lg:text-3xl text-gray-800 my-2">
            {project.frontmatter.title}
          </div>
          <div className="text-sm lg:text-base text-gray-800" dangerouslySetInnerHTML={{ __html: project.html }}></div>
          <div className="my-3 lg:my-10 lg:flex justify-between">
            <div className="flex items-center lg:w-3/4">
              <FiSettings className="text-2xl mr-2 w-1/12 lg:w-auto" color="#2d3748" />
              <div className="text-sm lg:text-base text-gray-800 uppercase w-11/12 ">{project.frontmatter.tech}</div>
            </div>
            <div className="flex items-center lg:p-0 pt-2 ">
              <FiArrowRight className="text-3xl lg:mx-2 mr-2 w-1/12 lg:w-auto" color="#2d3748" />

              <a href={project.frontmatter.link} className="text-sm lg:text-base text-gray-800 hover:underline">
                {project.frontmatter.slug}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="h-8 lg:h-16"></div>
    </div>
  )
}

export default ProjectCard
