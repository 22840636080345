import React, { useState, useEffect } from "react"
import { useSpring, animated, config } from "react-spring"
import { FiArrowDown } from "react-icons/fi"
import logo from "../../images/logo.png"
import { Link } from "react-scroll"

const ProjectHero = () => {
  const [distance, setDistance] = useState()

  useEffect(() => {
    typeof window !== `undefined` &&
      window.addEventListener("scroll", () => {
        setDistance(window.pageYOffset)
      })
    return () => {
      typeof window !== `undefined` &&
        window.removeEventListener("scroll", () => {
          setDistance(window.pageYOffset)
        })
    }
  }, [])

  const animationRight = useSpring({
    opacity: 1,
    transform: "translate3d(0, 0rem, 0)",
    from: { opacity: 0, transform: "translate3d(-15rem, 0, 0)" },
    config: config.slow,
    delay: 300,
  })

  const animationLeft = useSpring({
    opacity: 1,
    transform: "translate3d(0, 0rem, 0)",
    from: { opacity: 0, transform: "translate3d(15rem, 0, 0)" },
    config: config.slow,
    delay: 300,
  })

  const animationTop = useSpring({
    opacity: 1,
    transform: "translate3d(0, 0rem, 0)",
    from: { opacity: 0, transform: "translate3d(0, -5rem, 0)" },
    config: config.slow,
    delay: 300,
  })

  const animationBottom = useSpring({
    opacity: 1,
    transform: "translate3d(0, 0rem, 0)",
    from: { opacity: 0, transform: "translate3d(0, 5rem, 0)" },
    config: config.slow,
    delay: 500,
  })

  const scrollAnimation = useSpring({
    opacity:
      typeof window != `undefined` && distance > window.innerHeight / 3 ? 0 : 1,
    transform:
      typeof window != `undefined` && distance > window.innerHeight / 3
        ? "translate3d(0, -15rem, 0)"
        : "translate3d(0, 0rem, 0)",
    config: config.spring,
  })

  return (
    <div>
      <div className="flex justify-center h-screen items-center w-full">
        <animated.div style={scrollAnimation} className="w-full">
          <div className="lg:flex">
            <animated.div
              style={animationRight}
              className="lato font-light lg:w-1/2 text-2xl lg:text-5xl text-center lg:text-right text-gray-800"
            >
              Referenzen
            </animated.div>

            <animated.div
              className="mx-3 flex items-center justify-center"
              style={animationTop}
            >
              <img className="w-10 lg:w-16" src={logo} alt="Becker&&Zietz Logo"/>
            </animated.div>
            <animated.div
              style={animationLeft}
              className="lato lg:w-1/2 text-2xl lg:text-5xl text-center lg:text-left text-gray-800"
            >
              Erfahrung
            </animated.div>
          </div>
          <animated.div
            style={animationBottom}
            className="flex justify-center mt-10"
          >
            <Link
              activeClass="active"
              to="content"
              spy={true}
              smooth={true}
              offset={-80}
              duration={1000}
            >
              <FiArrowDown className="cursor-pointer mt-4 text-5xl text-center text-gray-800" />
            </Link>
          </animated.div>
        </animated.div>
      </div>
    </div>
  )
}

export default ProjectHero
