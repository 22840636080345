import React from "react"
import ProjectCard from "./ProjectCard"
import { graphql, useStaticQuery } from "gatsby"
import { Element } from "react-scroll"

const ProjectContent = ({ project }) => {
	const { allMarkdownRemark } = useStaticQuery(graphql`
		query ProjectDetailListing {
			allMarkdownRemark(
				sort: { order: ASC, fields: [frontmatter___order] }
				filter: { fileAbsolutePath: { regex: "/experience/" } }
			) {
				edges {
					node {
						html
						frontmatter {
							title
							link
							client
							date
							tech
							slug
						}
					}
				}
			}
		}
	`)

	return (
		<div className="">
			<Element name="content" className="element">
				{allMarkdownRemark.edges.map(({ node }) => (
					<ProjectCard
						className=""
						key={node.frontmatter.order}
						project={node}
					/>
				))}
			</Element>
			<div className="h-20"></div>
		</div>
	)
}

export default ProjectContent
